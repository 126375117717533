<template>
  <base-list :items="recentlyItems" />
</template>

<script>
import { useNotifications } from '@/modules/notifications/compositions/notifications';
import { onMounted, toRefs } from '@vue/composition-api';

export default {
  name: 'RecentlyNotificationList',
  props: {
    objectId: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { load, loading, recentlyItems } = useNotifications(props.objectId);

    onMounted(() => load());

    return { recentlyItems, loading };
  }
};
</script>

<style></style>
